body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Karla", sans-serif;
}

body,
html {
  overflow-x: hidden;
}

/* Hero */
.no-scroll {
  transition: 0.7s;
}

.scrolled {
  transition: 0.7s;
  background-color: #191102;
}

.first-hr {
  border: none;
  height: 0.05em;
  color: dimgray;
  background-color: gray;
  margin-left: -15px;
  margin-right: -15px;
  transition: 1s;
  opacity: 0.6;
}

.bgimage {
  width: 100%;
  height: 1000px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/background-home-min.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: 0px 0px 8px 2px #000000;
}

.navLogo {
  width: 25%;
  height: 100%
}

.navTop {
  color: white;
  padding-top: 30px;
  margin-bottom: -16px;
  font-family: "Karla", sans-serif;
}

.leftNav {
  text-align: left;
  font-size: 30px;
}

.leftNav p {
  font-family: "Oswald";
  color: white;
  margin-top: -15px;
  margin-left: 30px;
}

.rightNav {
  text-align: right;
  font-weight: bold;
}

.navitems li {
  margin-top: 0px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  text-decoration: none;
}

.navitems li:hover {
  text-decoration: underline;
}

.activeNav {
  color: #ff9f1c;
}

.notActive {
  color: white;
}

.scrollbar {
  position: fixed;
  margin-top: -9px;
}

.scrollbar-clicked {
  margin-top: -9px;
}

.hero-text {
  font-family: "Karla", sans-serif;
  position: absolute;
  top: 24%;
  text-align: left;
  color: white;
  min-width: 50%;
  margin-left: 50px;
}

.hero-text p {
  color: white;
}

.bigText {
  text-align: left;
  font-size: 65px;
  font-weight: bold;
}

.subText {
  opacity: 0.75;
  font-size: 20px;
  font-family: "Karla", sans-serif;
}

.heroButton {
  margin-top: 20px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(to bottom right, #ec7505, orange);
  color: white;
  height: 55px;
  width: 175px;
  font-size: 14px;
  font-weight: bold;
}

.down-button {
  position: absolute;
  left: 47%;
  top: 85%;
  text-align: center;
}

.hiddenElement {
  position: absolute;
  top: 126%;
  left: 50%;
  opacity: 0;
}

/* About Banner */

.high-temp {
  padding-top: 50px;
  margin-bottom: -50px;
  font-size: 35px;
  font-weight: bold;
}

.testclass {
  background-color: whitesmoke;
  padding-bottom: 30px;
}

.about-header {
  font-size: 45px;
  line-height: 1.1;
  margin-top: 92px;
  margin-right: 20px;
}

.threedots {
  margin-bottom: 20px;
  margin-top: -20px;
  float: left;
}

.about-text {
  margin-top: 100px;
  font-size: 16px;
}

.second-hr {
  border: none;
  height: 0.5px;
  /* Set the hr color */
  color: lightgray; /* old IE */
  background-color: lightgray; /* Modern Browsers */
}

/* More info */

.infoHeader {
  font-size: 35px;
  margin-top: 50px;
  font-weight: bold;
}

.subHead {
  margin-bottom: 60px;
  font-size: 16px;
  color: dimgray;
  font-weight: 200;
}

.icon-info {
  padding-bottom: 50px;
}

.lightning {
  margin-top: -12px;
}

.text-header {
  margin-bottom: 20px;
  font-size: 23px;
  transition: 0.3s;
}

.icon-text {
  font-size: 16px;
  margin-bottom: 70px;
  color: dimgray;
}

.MACHT-link:hover {
  color: orange;
}

#new-tech:hover {
  color: orange;
  transition: 0.3s;
}

.cord-image {
  background-color: #f9f9f9;
  padding-bottom: 30px;
  margin-bottom: -30px;
}

#cord {
  margin-top: 40px;
  margin-bottom: 55px;
  width: 400px;
  height: 500px;
}

.cordHeader {
  margin-top: -130px;
  font-size: 35px;
  font-weight: bold;
}

.cordText {
  color: dimgray;
  font-size: 16px;
  margin-top: 20px;
}

.cord-button {
  border: 2px solid orange;
  border-radius: 5px;
  padding: 5px 15px;
  margin-top: 10px;
  margin-bottom: 70px;
  background-color: #f9f9f9;
}

.cord-link {
  font-weight: bold;
  font-size: 20px;
  transition: 0.4s;
}

.cord-link:hover {
  color: orange;
  text-decoration: none;
}

/* Info with pics */
.car-info {
  background-color: whitesmoke;
}

.car-row {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
}

.car-header {
  font-size: 26px;
  font-weight: bold;
}

.car-link {
  font-weight: bold;
  text-decoration: none;
}

.car-link:hover {
  color: orange;
  transition: 0.4s;
  text-decoration: none;
}

#car {
  width: 90%;
  height: 100%;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.car-button {
  border: 2px solid orange;
  border-radius: 5px;
  padding: 5px 15px;
  margin-top: 10px;
  margin-bottom: 70px;
  background-color: whitesmoke;
}

.macht-hidden {
  opacity: 0;
}

/* MACHT */

.macht-header {
  font-size: 35px;
  font-weight: bold;
  margin-top: 30px;
}

#macht {
  width: 100%;
  margin-bottom: 50px;
}

/* Partner Banner */

.partner-image {
  width:50%;
}

#sbir {
  margin-bottom: 1rem;
}

#doe {
  margin-top: 1rem;
}

.partner-banner {
  background-color:whitesmoke;
  padding-bottom:3rem;
}

.vision-button {
  border: 2px solid orange;
  border-radius: 5px;
  padding: 5px 15px;
  margin-top: -10px;
  margin-bottom: 40px;
  background-color: white;
  font-weight: bold;
}

.vision-link:hover {
  color: orange;
  transition: 0.3s;
  text-decoration: none;
}

/* Newsletter */
.newsletter-container {
  background: linear-gradient(to bottom right, #ec7505, orange);
}

.inputBox {
  margin-top: 17px;
  height: 40px;
  border: none;
  border-radius: 3px;
}

.btn-secondary {
  height: 40px;
  font-size: 16px;
  background-color: #2d2d2a;
  border: 2px solid #2d2d2a;
  font-family: "Karla", sans-serif;
  margin-top: -7px;
}

.btn-secondary p {
  margin-top: -2px;
}

.outer {
  margin-top: 24px;
}

.form-control {
  font-size: 16px;
}

.is-invalid {
  font-size: 16px;
  border: 3px solid red;
}

.top-logo-page {
  width: 200px;
  margin-bottom: 1rem;
}

.content-mats {
  margin-top: 5rem;
}

.content-card {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.content-card-no-banner {
  margin-top: 1rem;
}

.content-background {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mat-header {
  margin-top: 3rem;
  font-weight: bold;
  font-size: 25px;
}

.mat-sub {
  font-size: 18px;
}

.mat-photo {
  width: 60rem;
}

ul {
  text-align: left;
  list-style-position: inside;
}

ul li { padding: 10px 0px; }

.news-bullets {
  font-size: 18px;
}

/* Service */

.service-text{
  display: inline-block;
  vertical-align: middle;
}

/* Footer */
.footer1 {
  background: linear-gradient(to top, black, #2d2d2a);
  width: 100%;
  height: 300px;
}

.logo {
  margin-top: 70px;
  color: white;
  font-family: "Oswald";
  font-size: 60px;
  text-align: center;
}

.addr {
  color: white;
  line-height: 1px;
  text-align: center;
  font-size: 12px;
}

.footer-text {
  color: white;
  font-size: 25px;
  margin-top: 80px;
}

.vertLine {
  border-left: 1px solid white;
  margin-top: 65px;
  margin-bottom: -30px;
}

.footer-links {
  list-style: none;
  color: white;
  padding-left: 0;
}

.about-footer:hover {
  text-decoration: underline;
}

/* Footer 2 */

.footer2 {
  background-color: #2d2d2a;
}

.footer2-logo {
  color: white;
  font-size: 28px;
  font-family: "Oswald";
  margin-left: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.copyright {
  color: white;
  text-align: left;
  margin-top: 30px;
}

/* Subscribed */
.bgimage2 {
  width: 100%;
  height: 625px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/random.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: 0px 0px 8px 2px #000000;
}

/* Contact Us Page */
.bgimage3 {
  width: 100%;
  height: 625px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/background-home-min.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  box-shadow: 0px 0px 8px 2px #000000;
  z-index: 999;
}

.hero-text1 {
  font-family: "Karla", sans-serif;
  position: absolute;
  top: 24%;
  text-align: left;
  color: white;
  min-width: 50%;
  margin-left: 50px;
}

.hero-text1 p {
  color: white;
}

.bigText1 {
  text-align: left;
  font-size: 65px;
  font-weight: bold;
}

.subText1 {
  opacity: 0.75;
  font-size: 20px;
  font-family: "Karla", sans-serif;
}

.preTextHeader p {
  font-size: 24px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact-details {
  margin-top: 2px;
  background-color: whitesmoke;
}

.preText {
  margin-top: 40px;
  font-size: 30px;
  color: black;
}

.address-card {
  margin-top: 80px;
  margin-bottom: 50px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.email-card {
  margin-top: 80px;
  margin-bottom: 50px;
  border-radius: 20px;
  padding-bottom: 57px;
  background: white;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.form-container {
  background-color: whitesmoke;
  margin-top: -15px;
}

.btn-info {
  background: linear-gradient(to bottom right, #ec7505, orange);
  border: none;
  font-weight: bold;
}

.btn-info:hover {
  background-color: dimgray;
  border-color: dimgray;
}

.contact-form {
  margin-bottom: 80px;
}

.contact-header {
  margin-top: 50px;
}

.email-header {
  margin-top: 80px;
  font-weight: bold;
  color: #ec7505;
}

.addr-header {
  margin-top: 50px;
  font-weight: bold;
  color: #ec7505;
}

#message-box {
  height: 120px;
}

.contact-card p {
  font-size: 18px;
  color: dimgray;
}

/* Sub and Contact confirmation */
.subscriptionText {
  font-size: 30px;
  margin-top: 160px;
  margin-bottom: 140px;
}

/* Images */
.pictureLink {
  width: 100%;
  height: 100%;
}

.pictureLinkHome {
  text-decoration: none;
  color: #d7263d;
}

.pictureLinkHome:hover {
  color: orange;
  text-decoration: none;
  transition: 0.3s;
}

/* Vision */
.bgimage4 {
  width: 100%;
  height: 625px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/vision.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  box-shadow: 0px 0px 8px 2px #000000;
  margin-bottom: 0.5rem;
}

.vision-header {
  font-size: 35px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 35px;
}

#vision {
  width: 100%;
  margin-bottom: 80px;
}

.bigText2 {
  font-size: 40px;
  font-weight: bold;
}

.subText2 {
  font-size: 20px;
}

.hero-text2 {
  font-family: "Karla", sans-serif;
  position: absolute;
  top: 27%;
  text-align: left;
  color: white;
  min-width: 50%;
  margin-left: 50px;
}

.employment-block  {
  padding-top: 6rem;
}
.employmentLogo {
  width: 250px;
  
}

@media screen and (max-width: 1520px) {
  footer1 {
    height: 350px;
  }

  .logo {
    margin-top: 90px;
  }
}

@media screen and (max-width: 1000px) {
  .bigText {
    font-size: 50px;
  }
}

@media screen and (max-width: 992px) {
  .carText {
    margin-top: 20px;
  }

  .car-row {
    margin-bottom: -70px;
  }

  .macht-header {
    margin-top: 100px;
  }
}

@media screen and (max-width: 900px) {
  footer1 {
    height: 400px;
  }

  .logo {
    margin-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .btn-secondary {
    margin-bottom: 20px;
  }

  .footer2-logo {
    text-align: center;
    margin-left: 0px;
  }

  .copyright {
    text-align: center;
    margin-top: 0px;
  }

  .carText {
    margin-left: 0px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 700px) {
  .logo {
    margin-top: 90px;
  }
}

@media screen and (max-width: 670px) {
  .logo {
    margin-top: 130px;
  }

  .vertLine {
    margin-bottom: 20px;
  }

  .footer-text {
    margin-top: 70px;
  }
}

@media screen and (max-width: 620px) {
  .navitems {
    margin-right: 5px;
    overflow-wrap: normal;
  }
  .scrollbar {
    margin-top: -25px;
  }

  .logo {
    margin-top: 130px;
  }

  .hero-text {
    padding-top: 120px;
  }

  .navitems li {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 578px) {
  .logo {
    margin-top: 60px;
  }
}

@media screen and (max-width: 576px) {
  .about-text {
    margin-top: 0px;
    margin-bottom: 50px;
  }

  footer {
    height: auto;
  }

  .footer-text {
    margin-top: -30px;
    text-align: center;
  }

  .footer-links {
    text-align: center;
    margin-bottom: 40px;
  }

  .vertLine {
    display: hidden;
  }

  .hero-text {
    top: 16%;
  }

  .bigText {
    font-size: 40px;
  }

  .hero-text1 {
    top: 25%;
    left: -5%;
  }

  .bigText1 {
    font-size: 40px;
  }

  .down-button {
    left: 44%;
  }

  .navitems {
    margin-left: -10px;
  }

  .email-header {
    margin-top: 40px;
  }

  .address-card {
    margin-bottom: 0px;
  }

  .cordText {
    margin-left: 10px;
    margin-right: 10px;
  }

  .footer2-logo {
    margin-left: 0px;
  }

  #car {
    width: 100%;
    height: 100%;
  }

  .car-row {
    padding-bottom: 40px;
  }
}

.flexibleImage {
  max-width: 100%;
  height: auto;
}

.newsLink {
  color: orange;
  text-decoration: underline;
}
